import {
  Component,
  Inject,
  ViewEncapsulation,
  ViewChild,
  Injector,
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CartService } from '@shared/module/cart/services/cart.service';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '@shared/providers/datepickerFormatter';
import { NotiService } from '@shared/module/notification';
import { Store } from '@shared/models/store.model';
import { StoreService } from '@shared/services/store.service';
import { ResponeResult } from '@core/models/ResponeResult';
import { BlockService } from '@shared/module/block/services/block.service';
import { ModalService } from '@shared/module/modal';
import { BaseComponent } from '@core/BaseComponent';
import { TranslatesService } from '@shared/module/translate';
import { OrderService } from '@shared/services/order.service';
import { ScriptService } from '@shared/script.service';
import { MegaPay, MegaPayResponse } from '@shared/models/megapay.model';
import stringUtil from '@shared/utils/string.util';
import { VNPayResponse } from '@shared/models/vnpay.model';
import { PayOnline, PayOnlineID } from '@shared/models/pay-online.model';
import { MomoResponse } from '@shared/models/momo.model';
//import { ModalService } from 'src/app/modal';
//import { CartItem, Cart } from 'src/app/common/models/cartItem';
declare function openPayment(mode, domain): any;

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // {provide: NgbDateAdapter, useClass: CustomAdapter},
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class PayComponent extends BaseComponent {
  stores: Store[];
  listPayment = [];
  megaPay: MegaPay = new MegaPay();
  megaRes: MegaPayResponse;

  vnpayRes: VNPayResponse;

  mindate: NgbDate;
  @ViewChild('paypay') paypay;
  @ViewChild('payMethod') payMethod;
  listPayOnline: PayOnline[] = [
    {
      type: PayOnlineID.vnpay,
      name: 'VNPAY / ATM',
      logo: 'assets/images/vn-pay.png',
    },
    {
      type: PayOnlineID.momo,
      name: 'Ví MoMo',
      logo: 'assets/images/momo-pay.png',
    },
    // {
    //   type:PayOnlineID.zalo,
    //   name:"ZALO",
    //   logo:"assets/images/zalo-pay.png",
    // },
  ];
  payOnlineSelected: PayOnline;

  constructor(
    protected notiService: NotiService,
    public cartService: CartService,
    private storeService: StoreService,
    private blockService: BlockService,
    private modalService: ModalService,
    private translate: TranslatesService,
    private orderService: OrderService,
    private ngbModal: NgbModal,
    private datePipe: DatePipe,
    injector: Injector,
    private script: ScriptService,
    private calendar: NgbCalendar
  ) {
    super(injector);
    this.mindate = this.calendar.getToday();
  }

  ngOnInit() {
    this.seo.title = 'Thanh toán';
    this.updateSEO(true);
    this.script
      .load('megapay')
      .then()
      .catch((error) => console.log(error));
    // this.handleCheckIsPaymentMega();
    if (
      this.route.snapshot.queryParams?.vnp_TxnRef &&
      this.route.snapshot.queryParams?.vnp_TxnRef != ''
    ) {
      this.handleCheckIsPaymentVNPAY();
    } else if (
      this.route.snapshot.queryParams?.partnerCode &&
      this.route.snapshot.queryParams?.partnerCode != ''
    ) {
      this.handleCheckIsPaymentMomo();
    } else if (this.route.snapshot.queryParams) {
      this.handleCheckIsPaymentZalo();
    }

    this.getStore();
    this.translate.onLoad().then(() => {
      this.listPayment = [
        {
          id: 1,
          title: this.translate.instant('pay.payment_list.clinic'),
          description: this.translate.instant('pay.payment_list.clinic_des'),
        },
        {
          id: 2,
          title: this.translate.instant('pay.payment_list.bank'),
          description: this.translate.instant('pay.payment_list.bank_des'),
        },
        {
          id: 3,
          title: this.translate.instant('pay.payment_list.online'),
          description: this.translate.instant('pay.payment_list.online_des'),
        },
      ];
      this.cartService.value.paymentType = 1;
      this.cartService.value.paymentName = this.translate.instant(
        'pay.payment_list.clinic'
      );
    });

    this.cartService.value.step = 1;
    this.cartService.value.note = '';

    let isTest = false;
    for (let i = 0; i < this.cartService.value.cartItems.length; i++) {
      const element = this.cartService.value.cartItems[i];
      if (element.packageName.includes('GOI TEST')) {
        isTest = true;
      } else {
        isTest = false;
      }
    }
    if (!isTest) {
      this.listPayOnline.splice(0, 1);
    }
  }

  doChooseOnlineMethod(item) {
    if (this.payOnlineSelected) {
      this.payOnlineSelected.checked = false;
    }
    this.payOnlineSelected = item;
    item.checked = true;
  }

  handleCheckIsPaymentMega() {
    this.translate.onLoad().then(() => {
      try {
        this.megaRes = new MegaPayResponse(this.route.snapshot.queryParams);
        if (
          this.megaRes.merId &&
          this.megaRes.merId != '' &&
          this.megaRes.merchantToken &&
          this.megaRes.merchantToken != ''
        ) {
          this.megaRes.amount = Number(this.megaRes.amount);
          if (this.megaRes.resultCd == '00_000') {
            this.orderService
              .checkoutMegaPayRes(this.megaRes)
              .then((res: ResponeResult) => {
                if (res.isOk) {
                  // this.modalService.open("paypay");
                  this.ngbModal
                    .open(this.paypay, { centered: true })
                    .result.then((result) => {});

                  this.cartService.clearAllItem();
                }
              })
              .catch();
          } else {
            this.notiService.error(
              this.translate.instant('pay.message.payment_error')
            );
          }
        }
      } catch {}
    });
  }

  handleCheckIsPaymentVNPAY() {
    this.translate.onLoad().then(() => {
      try {
        this.vnpayRes = new VNPayResponse(this.route.snapshot.queryParams);
        if (
          this.vnpayRes.vnp_TransactionNo &&
          this.vnpayRes.vnp_TransactionNo != '' &&
          this.vnpayRes.vnp_SecureHash &&
          this.vnpayRes.vnp_SecureHash != ''
        ) {
          this.vnpayRes.vnp_TxnRef = Number(this.vnpayRes.vnp_TxnRef);
          this.vnpayRes.vnp_Amount = Number(this.vnpayRes.vnp_Amount);
          // this.vnpayRes.vnp_OrderInfo = encodeURI(this.vnpayRes.vnp_OrderInfo);
          if (this.vnpayRes.vnp_ResponseCode == '00') {
            this.orderService
              .checkoutVNPayRes(this.vnpayRes)
              .then((res: ResponeResult) => {
                if (res.isOk) {
                  // this.modalService.open("paypay");
                  this.ngbModal
                    .open(this.paypay, { centered: true })
                    .result.then((result) => {});

                  this.cartService.clearAllItem();
                }
              })
              .catch();
          } else {
            this.notiService.error(
              this.translate.instant('pay.message.payment_error')
            );
          }
        }
      } catch {}
    });
  }

  handleCheckIsPaymentMomo() {
    this.translate.onLoad().then(() => {
      try {
        let momoRes = new MomoResponse(this.route.snapshot.queryParams);
        if (
          momoRes.partnerCode &&
          momoRes.partnerCode != '' &&
          momoRes.signature &&
          momoRes.signature != ''
        ) {
          momoRes.resultCode = Number(momoRes.resultCode);
          // this.vnpayRes.vnp_OrderInfo = encodeURI(this.vnpayRes.vnp_OrderInfo);
          if (momoRes.resultCode == 0) {
            this.orderService
              .checkoutMomoRes(momoRes)
              .then((res: ResponeResult) => {
                if (res.isOk) {
                  // this.modalService.open("paypay");
                  this.ngbModal
                    .open(this.paypay, { centered: true })
                    .result.then((result) => {});

                  this.cartService.clearAllItem();
                }
              })
              .catch();
          } else {
            this.notiService.error(
              this.translate.instant('pay.message.payment_error')
            );
          }
        }
      } catch {}
    });
  }

  handleCheckIsPaymentZalo() {
    this.translate.onLoad().then(() => {
      try {
        this.vnpayRes = new VNPayResponse(this.route.snapshot.queryParams);
        if (
          this.vnpayRes.vnp_TransactionNo &&
          this.vnpayRes.vnp_TransactionNo != '' &&
          this.vnpayRes.vnp_SecureHash &&
          this.vnpayRes.vnp_SecureHash != ''
        ) {
          this.vnpayRes.vnp_TxnRef = Number(this.vnpayRes.vnp_TxnRef);
          this.vnpayRes.vnp_Amount = Number(this.vnpayRes.vnp_Amount);
          // this.vnpayRes.vnp_OrderInfo = encodeURI(this.vnpayRes.vnp_OrderInfo);
          if (this.vnpayRes.vnp_ResponseCode == '00') {
            this.orderService
              .checkoutVNPayRes(this.vnpayRes)
              .then((res: ResponeResult) => {
                if (res.isOk) {
                  // this.modalService.open("paypay");
                  this.ngbModal
                    .open(this.paypay, { centered: true })
                    .result.then((result) => {});

                  this.cartService.clearAllItem();
                }
              })
              .catch();
          } else {
            this.notiService.error(
              this.translate.instant('pay.message.payment_error')
            );
          }
        }
      } catch {}
    });
  }

  getPaymentItem(id) {
    return this.listPayment.find((x) => x.id == id);
  }

  getStore() {
    let params = new HttpParams();
    params = params.append('isActive', '1');
    this.storeService.getStore().then((res: ResponeResult) => {
      this.stores = res.repData;
      if (this.stores.length == 1) {
        this.cartService.value.storeId = this.stores[0].storeId;
      }
      // this.stores.unshift(new Store());
    });
  }

  onSubmitCustomer(frm: NgForm) {
    if (frm.valid) {
      this.nextStep();
    } else {
      this.notiService.error('Vui lòng điền đủ thông tin');
    }
  }

  onSubmitCheckout(frm: NgForm) {
    if (frm.valid) {
      this.blockService.load('block-pay');
      // console.log("giỏ hàng",this.cartService.value);
      let customerBirthday = this.cartService.value.customer.customerBirthday;
      let today = new Date();
      var tmpBookingDate = `${today.getFullYear()}-${stringUtil.padLeadingZeros(
        today.getMonth(),
        2
      )}-${stringUtil.padLeadingZeros(today.getDay(), 2)}`;
      if (customerBirthday) {
        tmpBookingDate = `${customerBirthday.year}-${stringUtil.padLeadingZeros(
          customerBirthday.month,
          2
        )}-${stringUtil.padLeadingZeros(customerBirthday.day, 2)}`;
      }

      // customerBirthday =  this.datePipe.transform(customerBirthday, "yyyy-MM-dd") || "";

      // this.cartService.value.customer.customerBirthday = tmpBookingDate;

      let cartValue = Object.assign({}, this.cartService.value);
      let customerValue = Object.assign({}, cartValue.customer);
      cartValue.customer = customerValue;
      cartValue.customer.customerBirthday = tmpBookingDate;

      if (this.cartService.value.paymentType == 3) {
        // THANH TOAN MOMO
        this.ngbModal
          .open(this.payMethod, {
            centered: true,
            size: 'lg',
            windowClass: 'modal-payment-method',
          })
          .result.then((res) => {
            if (res) {
              if (this.payOnlineSelected) {
                if (this.payOnlineSelected.type == PayOnlineID.momo) {
                  this.payoutWithMomo(cartValue);
                } else if (this.payOnlineSelected.type == PayOnlineID.vnpay) {
                  this.payoutWithVNPay(cartValue);
                } else {
                  this.payoutWithZaloPay(cartValue);
                }
              } else {
                this.blockService.unload('block-pay');
                this.notiService.error('Vui lòng chọn Cổng thanh toán');
              }
            } else {
              this.blockService.unload('block-pay');
            }
          });
        // this.payoutWithMomo(cartValue);

        // THANH TOAN MEGAPAY
        // this.orderService.checkoutMegaPay(cartValue).then((res: ResponeResult)=>{
        //   let order:Order = res.repData.order;
        //   this.cartService.value.orderId = order.orderId;

        //   let megares:MegaPay = res.repData.mega;
        //   this.megaPay.domain = megares.domain;
        //   this.megaPay.merId = megares.merId;
        //   this.megaPay.merTrxId = megares.merTrxId;
        //   this.megaPay.timeStamp = megares.timeStamp;
        //   this.megaPay.token = megares.token;
        //   this.megaPay.description = megares.description;
        //   this.megaPay.goodsNm = megares.goodsNm;
        //   this.megaPay.currency = megares.currency;
        //   this.megaPay.invoiceNo = megares.invoiceNo;
        //   this.megaPay.notiUrl = megares.notiUrl;
        //   this.megaPay.userLanguage = megares.userLanguage;
        //   this.megaPay.amount = megares.amount;
        //   setTimeout(() => {
        //     openPayment(1, "https://sandbox.megapay.vn:2810/");
        //   }, 1);
        // }).catch(err=>{
        //   this.notiService.error("Gửi giỏ hàng lên server không thành công");
        // }).finally(()=>{
        //   this.blockService.unload("block-pay");
        // })
      } else {
        this.orderService
          .checkout(cartValue)
          .then((res) => {
            // this.modalService.open("paypay");
            this.ngbModal
              .open(this.paypay, { centered: true })
              .result.then((result) => {
                this.cartService.clearAllItem();
              });
          })
          .catch((err) => {
            this.notiService.error('Gửi giỏ hàng lên server không thành công');
          })
          .finally(() => {
            this.blockService.unload('block-pay');
          });
      }
    } else {
      this.notiService.warn('Vui lòng xác nhận các điều khoản trước');
    }
  }
  doPayOnlineNow() {}
  payoutWithMomo(cartValue) {
    this.orderService
      .checkoutMomo(cartValue)
      .then((res: ResponeResult) => {
        document.location.href = res.repData;
      })
      .catch((err) => {
        this.notiService.error('Gửi giỏ hàng lên server không thành công');
      })
      .finally(() => {
        this.blockService.unload('block-pay');
      });
  }

  payoutWithVNPay(cartValue) {
    this.orderService
      .checkoutVNPAT(cartValue)
      .then((res: ResponeResult) => {
        document.location.href = res.repData;
      })
      .catch((err) => {
        this.notiService.error('Gửi giỏ hàng lên server không thành công');
      })
      .finally(() => {
        this.blockService.unload('block-pay');
      });
  }

  payoutWithZaloPay(cartValue) {
    this.orderService
      .checkoutZaloPay(cartValue)
      .then((res: ResponeResult) => {
        document.location.href = res.repData;
      })
      .catch((err) => {
        this.notiService.error('Gửi giỏ hàng lên server không thành công');
      })
      .finally(() => {
        this.blockService.unload('block-pay');
      });
  }

  completeOrder(isDefault: boolean = true, modal) {
    // this.modalService.close('paypay')
    this.cartService.clearAllItem();
    if (modal) modal.close();

    if (isDefault) {
      this.router.navigate(['/goi-kham'], { relativeTo: this.route });
      // this.router.navigate(['/goi-kham']);
    }
  }

  nextStep() {
    this.cartService.value.step += 1;
    this.cartService.value.tabselect += 1;
  }
  backStep() {
    this.cartService.value.step -= 1;
    this.cartService.value.tabselect -= 1;
  }
  setStep(step: number = 1) {
    if (this.cartService.value.step > step) {
      this.cartService.value.step = step;
      this.cartService.value.tabselect = step;
    }
  }

  changePaymentType(paymentType: number) {
    this.cartService.value.paymentType = paymentType;
    let payment = this.listPayment.find((x) => x.id == paymentType);
    if (payment) {
      this.cartService.value.paymentName = payment.title;
    }
  }

  //openModal(id: string) {
  //  this.modalService.open(id);
  //}

  //closeModal(id: string) {
  //  this.modalService.close(id);
  //}
}
