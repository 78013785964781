export class ReferLink{
  rowId:number;
  title:string;
  linkType:number;
  urlLink:string;
  description:string;
  date:string;
}

export class ReferLinkDTO{
  thongBao:ReferLink[] = [];
  thuMoi:ReferLink[] = [];
  vanBan:ReferLink[] = [];
}