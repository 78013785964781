import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class BookingService extends BaseService {
  //frontend
  public getInfo() {
    return this.get("home/GetBooking");
  }

  public booking(booking) {
    return this.post("home/AddBooking", booking);
  }

  //backend
  public getInitalBooking(query) {
    return this.get("admin/GetInitalBooking", query);
  }
  public getBooking(query) {
    return this.get("admin/GetBooking", query);
  }

  public getBookingDashboard(query) {
    return this.get("admin/dashboard/booking", query);
  }
  public saveBooking(body) {
    return this.post("admin/saveBooking", body);
  }
}