import { ScriptLoaderService } from './../../../shared/services/script-loader.service';
import {
  Component,
  ElementRef,
  Inject,
  Injector,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AdvertiseService } from '@shared/services/advertise.service';
import { QueryObj } from '@shared/models/query.model';
import { AnounmentDTO } from '@shared/models/anounment.model';
import { ResponeResult } from '@core/models/ResponeResult';
import {
  NgbCalendar,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@core/BaseComponent';
import { ModalService } from '@shared/module/modal';
import { DOCUMENT } from '@angular/common';
import { TextdataService } from '@shared/services/textdata.service';
import { Observable, fromEvent, merge } from 'rxjs';
@Component({
  selector: 'app-routing-app',
  templateUrl: './frontend-app.component.html',
  styleUrls: ['./frontend-app.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FronendAppComponent extends BaseComponent {
  public adv: AnounmentDTO = new AnounmentDTO();
  companyInfo: Object = new Object();
  seoConfig: Object = new Object();
  isLoaded: boolean = false;

  @ViewChild('advertise') advertise;

  constructor(
    private advertiseService: AdvertiseService,
    private ngbModal: NgbModal,
    private modalService: ModalService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document,
    private textdataService: TextdataService,
    private scriptLoaderService: ScriptLoaderService,
    injector: Injector,
    private el: ElementRef
  ) {
    super(injector, false);
    // idleService.idle$.subscribe(s => console.log('im idle, zzz'));
    // idleService.wake$.subscribe(s => console.log('im awake!'));
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      const mouseEnter$: any = fromEvent(this.el.nativeElement, 'mouseenter');
      const mouseOver$: any = fromEvent(this.el.nativeElement, 'mouseover');
      const scroll$: any = fromEvent(window, 'scroll');
      // Kết hợp các sự kiện vào một sự kiện tổng hợp
      const interactions$ = merge(mouseEnter$, mouseOver$, scroll$);

      interactions$.subscribe(() => {
        // Khi có sự tương tác người dùng, tải GTM
        this.loadScripts();
      });
    }
  }

  loadScripts() {
    if (this.isLoaded) return;
    this.isLoaded = true;
    if (this.isBrowser) {
      this.scriptLoaderService
        .loadStyles('lightbox', 'fontawesome', 'aos')
        .then((x) => {
          this.addonSEO();
          this.scriptLoaderService
            .load('aos', 'bootstrap')
            .then((data) => {})
            .catch((error) => console.log(error));
        });
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.getDataInfo();
      this.getData();
    }
  }

  addonSEO() {
    const script = this._renderer.createElement('script');
    script.text = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true; j.defer=true; j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W9G33DR');

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KN5VR4Q');

    `;

    this.route.queryParams.subscribe((params) => {
      this._renderer.appendChild(this._document.head, script);
    });
  }

  getDataInfo() {
    this.textdataService
      .getCompanyInfo()
      .then((res) => {
        this.companyInfo = res;
      })
      .catch()
      .finally(() => {});

    // this.textdataService.getSEOconfig().then((res) => {
    //   this.seoConfig = res;

    //   this.route.queryParams.subscribe(params => {

    //     // const strHead = this.seoConfig["HEADER"].longDescription;
    //     // const head = this._renderer.createElement('div');
    //     // this._renderer.setProperty(head, 'outerHTML',strHead);
    //     // this._renderer.appendChild(this._document.head, head);

    //     // const strBody =  "<script>alert('Ok')</script>";//this.seoConfig["BODY"].longDescription;
    //     // const body = this._renderer.createElement('div');
    //     // this._renderer.setProperty(body, 'outerHTML',strBody);
    //     // //  this._renderer.appendChild(this._document.body, body);
    //     // this._renderer.insertBefore(this._document.body, body, this._document.body.firstChild);

    //     const strFooter = `<script>alert("xxx");</script>`;// this.seoConfig["FOOTER"].longDescription;
    //     const footer = this._renderer.createElement('div');

    //     this._renderer.appendChild(this._document.body, footer);
    //     this._renderer.setProperty(footer, 'outerHTML',strFooter);
    //   });

    // }).catch().finally(() => {

    // });
  }
  getData() {
    let queryAdv = new QueryObj();
    queryAdv.isPublish = 1;
    queryAdv.fromDate = this.formatter.format(
      this.calendar.getNext(this.calendar.getToday(), 'd', 1)
    );
    queryAdv.toDate = this.formatter.format(this.calendar.getToday());
    let curTime = Math.floor(
      Date.parse(this.formatter.format(this.calendar.getToday())) / 1000
    );
    let showpopup = {
      count: 0,
      time: curTime,
      id_pos: 0,
    };

    try {
      let strShowpopup = localStorage.getItem('showpopup');
      let tmpShowPopup = JSON.parse(strShowpopup);
      if (tmpShowPopup) {
        if (tmpShowPopup.time + 1800 <= curTime) {
          // sau 30 phút
          showpopup.count = 0;
        } else {
          showpopup.count = tmpShowPopup.count;
          showpopup.id_pos = tmpShowPopup.id_pos;
        }
      }
      this.advertiseService
        .GetAdvertise(queryAdv)
        .then((res: ResponeResult) => {
          if (res.repData.length > 0) {
            this.adv = res.repData[0];
            if (showpopup.count < 1) {
              this.doShowPopup(showpopup);
            } else if (this.adv.anounmentId != showpopup.id_pos) {
              showpopup.count = 0;
              this.doShowPopup(showpopup);
            }
          }
        })
        .finally(() => {});
    } catch (error) {}
  }

  doShowPopup(showpopup) {
    if (this.isBrowser) {
      setTimeout(() => {
        showpopup.count += 1;
        showpopup.id_pos = this.adv.anounmentId;
        localStorage.setItem('showpopup', JSON.stringify(showpopup));

        this.ngbModal
          .open(this.advertise, {
            windowClass: 'popup',
            size: 'md',
            centered: true,
          })
          .result.then((result) => {
            if (result) {
            }
          });
      }, 1000);
    }
  }
}
